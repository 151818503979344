import * as React from "react"
import { Link } from "gatsby"

import Header from "../components/layout/header"
import Footer from "../components/layout/footer"
import Pricing from "../sections/index/pricing"

const faqs = [
  {
    id: 1,
    question: 'What is Boundary CMS?',
    answer:
      'Boundary CMS is a developer friendly headless content management system that uses Configuration as Code to improve flexibility and ease of use for both developers and content writers.',
  },
  {
    id: 2,
    question: "What is a Headless CMS?",
    answer:
      "Headless CMS is a content management system that does not rely on a browser to render the content. Instead, content is served via an API, which gives more flexibility as to how it is used.",
  },
  {
    id: 3,
    question: "What is Configuration as Code?",
    answer: "Configuration as code is a software development practice where the configuration of a piece of software is stored in source code. One benefit this brings is that your CMS interface will always match the website source code.",
  },
  {
    id: 4,
    question: "What other features does Boundary have planned?",
    answer:
      "Currently we are building all the most important features you would expect a CMS to have. But in the future, we want to keep building on the developer experience and blur the line between an open-source, self hosted CMS and a hosted SaaS CMS. This would be the incorporation of serverless functions, themes, developer tools and more.",
  },
  {
    id: 5,
    question: "How can I join Boundary on this journey?",
    answer:
      <>You can register for our beta launch list <Link to="/register" className="text-white">here</Link>!</>,
  },
]

const PricingPage = () => {
  return (
    <div className="min-h-screen bg-primary-900 text-white">
      <div className="container mx-auto px-2">
        <Header />

        <div className="pt-24">
          <div className="sm:flex sm:flex-col sm:align-center mb-6">
            <h1 className="text-5xl tracking-tight font-bold sm:text-center">Pricing Plans</h1>

            <p className="mt-5 text-xl sm:text-center">
              
            </p>
          </div>

          <Pricing />
        </div>

        <div className="py-16 lg:py-20">
          <div className="lg:grid lg:grid-cols-3 lg:gap-8">
            <div className="space-y-4">
              <h2 className="text-3xl tracking-tight font-bold">Frequently asked questions</h2>

              <p className="text-lg text-primary-400">
                Find what you're looking for here.
              </p>
            </div>

            <div className="mt-12 lg:mt-0 lg:col-span-2">
              <dl className="space-y-12">
                {faqs.map((faq) => (
                  <div key={faq.id}>
                    <dt className="text-lg leading-6">{faq.question}</dt>
                    <dd className="mt-2 text-base text-primary-400">{faq.answer}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  )
}

export const Head = () => <>
  <title>Not found - Boundary CMS</title>

  <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
  <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
  <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
  <link rel="manifest" href="/site.webmanifest" />
  <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#515BE7" />
  <meta name="msapplication-TileColor" content="#515BE7" />
  <meta name="theme-color" content="#ffffff" />
</>

export default PricingPage
